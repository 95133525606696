import Views from "./Views.js";

class AddTask extends Views {
  addHandlerNewTask(handler) {
    //
    //
    //
    this.btnAdd.addEventListener("click", () => {
      const modal = document.querySelector(".modal--window");

      this.showOverlay(modal);
      this.showDoneBtn();
      this.inputField.focus();

      // this.toggleModal();
    });
    //
    //
    //
    this.btnCancel.addEventListener("click", () => {
      this.resetModal(this.chkDueBy, this.dateControl); //passing e to acces e.target.
      this.hideOverlay(this.modal);

      this.clearMainTextField();
    });
    //
    //
    //
    this.chkDueBy.addEventListener("change", () => {
      this.toggleDueBy(this.chkDueBy, this.dateControl);
    });
    //
    //
    //

    this.btnDone.addEventListener("click", () => {
      // if (!localStorageTasks[0]) taskView.toggleAddTaskMessage();

      // isTaskMenuActive is passed into the handler to reset state.activeTask in controller
      // if false model.state.activeTaskId = false
      // This is neccesary so that when a new task is created the taskMenu can be inserted under the correct task--item.
      // const isTaskMenuActive = this.taskMenu ? true : false;

      // if due date is opted in, returns iso string, else false

      const [textInput, due] = this.getUserInputs();

      if (!textInput) {
        this.hideOverlay(this.modal);
        this.displayMessage("Oops! Something was missing.");
        this.resetModal(this.chkDueBy, this.dateControl);

        return;
      }

      if (this.chkDueBy.checked) handler(textInput, due);
      else handler(textInput);

      this.clearMainTextField();
      this.resetModal(this.chkDueBy, this.dateControl);
      this.hideOverlay(this.modal);
      this.disableAddTaskMessage();
    });
  }

  showDoneBtn() {
    this.btnSave.classList.add("hidden");
    this.btnDone.classList.remove("hidden");
  }
}

export default new AddTask();

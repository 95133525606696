export const LOCALE = navigator.language;
export const DATE_STYLE = { dateStyle: "long" };
export const TIME_STYLE = { timeStyle: "short" };
export const MESSAGE_DISPLAY_SECONDS = 3;
export const UNDO_SECONDS = 8;
export const DARK_MODE_PRIMARY_COLOR = "#000";
export const DARK_MODE_SECONDARY_COLOR = "#444";
export const DARK_MODE_TEXT_COLOR = "#fff";
export const LIGHT_MODE_PRIMARY_COLOR = "#fff";
export const LIGHT_MODE_SECONDARY_COLOR = "#f5f5f5";
export const LIGHT_MODE_TEXT_COLOR = "#444";

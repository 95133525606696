export const state = {
  activeTaskId: false,
  tasks: [
    // { id: 1, completed: false, created: "", text: "Do landry", due: false },
    // { id: 2, completed: false, created: "", text: "Buy groceries", due: false },
    // { id: 3, completed: false, created: "", text: "Send email", due: false },
  ],
  accentColor: "",
  darkMode: false,
  visited: false,
};

export const deletedTasks = [];
export let timeoutReference;

// Task object
export const Task = class {
  id;
  completed;
  created;
  text;
  due;
  constructor(text, dueby) {
    this.id = this._generateId();
    this.completed = false;
    this.created = new Date().toISOString(); // returns today string
    this.text = text;
    this.due = dueby;

    const taskObject = {
      id: this.id,
      completed: this.completed,
      created: this.created,
      text: this.text,
      due: this.due,
    };

    this._saveToState(taskObject);
  }

  _generateId() {
    return state.tasks.length > 0
      ? Math.max(...state.tasks.map((task) => task.id)) + 1
      : 1;
  }

  _saveToState(taskObj) {
    state.tasks.push(taskObj);
  }

  saveToLocalStorage() {
    localStorage.setItem(this.id.toString(), JSON.stringify(this));
  }
};

export const getTasksFromLocalStorage = function () {
  const localStorageJsonStrings = Object.entries(localStorage).map(
    (taskInLocalStorage) => {
      // Sets the prototype chain so methods from Task constructor can be used by the tasks recovered from localStorage
      return taskInLocalStorage[1];
    }
  );

  return localStorageJsonStrings
    .map((jsonString) => {
      if (jsonString.length > 10) return JSON.parse(jsonString);
      else return "";
    })
    .filter((currentObject) => currentObject);
};

export const saveColorToLocalStorage = function (color) {
  localStorage.setItem("accent", color);
};

export const saveDarkModeToLocalStorage = function (bool) {
  localStorage.setItem("darkMode", bool);
};

export const loadColorFromLocalStorage = function () {
  return localStorage.getItem("accent");
};

export const preserveItem = function (item) {
  return localStorage.getItem(item);
};

import * as model from "./model.js";
import optionsView from "./views/optionsView.js";
import taskView from "./views/taskMenuView.js";
import taskManagerView from "./views/taskManagerView.js";
import addTaskView from "./views/addTaskView.js";
import darkModeView from "./views/darkModeView.js";
const controlMenu = function (e) {
  const activeTaskId = taskView.getTaskId(e);

  if (!activeTaskId) return;
  model.state.activeTaskId = activeTaskId; //storing active task in state

  // Resets, then Adds "active: true" to the task that has the menu opened

  // RenderMenu() returns true if it renders the menu or false if it removes it. This value we can assign to model.state.task[i].active. View function for more.
  model.state.tasks[activeTaskId - 1].active = true;
  const taskObject = taskView.getTaskObjectWithId(
    activeTaskId,
    model.state.tasks
  );

  taskView.renderMenu(activeTaskId, taskObject);
};

const controlAddTasks = function (text, dueby = false) {
  // sets model.state.activeTaskId to false. This is neccesary to reset activeTaskId if the addTask button is pressed after a task menu is rendered(which sets activeTaskId on state every time) then closed.

  // if (!isTaskMenuActive) model.state.activeTaskId = false;

  const newTask = new model.Task(text, dueby); // Everytime a new task is created, a new task is pushed into state.

  // Saving new task in LocalStorage

  newTask.saveToLocalStorage();
  // model.saveToLocalStorage(newTask);
  addTaskView.renderTask(newTask);
};

//
//
//

// This function is used to update a task in localStorage
const controlSavedTaskUpdate = function (task) {
  localStorage.setItem(task.id, JSON.stringify(task));
};
//
//
//
//
//
//

//
//
//
const controlEdit = function () {
  const id = model.state.activeTaskId;
  const taskToEdit = taskManagerView.findTaskWithId(id);

  const [textInput, due] = taskManagerView.getUserInputs();

  if (!textInput)
    return taskManagerView.displayMessage("Cannot edit without text");

  const task = model.state.tasks.filter((task) => task.id === +id)[0];

  const editedTask = {
    id: task.id,
    completed: task.completed,
    created: task.created,
    text: textInput,
    due: due,
    edited: true,
  };
  console.log(model.state);
  model.state.tasks[id - 1] = editedTask;
  console.log(model.state);

  taskManagerView.renderTask(editedTask);

  controlSavedTaskUpdate(editedTask);

  taskToEdit.remove();
  document.querySelector(".task--menu")?.remove();
};
//
//
//
//

const controlDelete = function () {
  const selectedTaskId = model.state.activeTaskId;
  const selectedTaskElement = taskManagerView.findTaskWithId(selectedTaskId);

  const taskObject = model.state.tasks.filter(
    (task) => task.id === +selectedTaskId
  )[0];

  model.deletedTasks.push({ taskElement: selectedTaskElement, taskObject });

  localStorage.removeItem(selectedTaskId);

  // clears timeout
  if (model.state.timeoutReference) clearTimeout(model.state.timeoutReference);

  // adds addTaskMessage if model.state.tasks < 2 (taking into account the last one about to be deleted)
  if (model.state.tasks.length < 2) taskManagerView.enableAddTaskMessage();
  return selectedTaskElement;
};
//
//

const controlUndo = function () {
  return model.deletedTasks.map((deletedTask) => {
    localStorage.setItem(
      deletedTask.taskObject.id,
      JSON.stringify(deletedTask.taskObject)
    );
    return deletedTask;
  });
};

const controlDeleteAll = function () {
  model.state.tasks = [];
  // preserving darkMode and accent from local storage, then clearing local storage, then adding darkMode and accent back.
  const [visited, darkMode, accent] = [
    model.preserveItem("visited"),
    model.preserveItem("darkMode"),
    model.preserveItem("accent"),
  ];
  localStorage.clear();
  localStorage.setItem("visited", visited);
  localStorage.setItem("darkMode", darkMode);
  if (accent) localStorage.setItem("accent", accent);
};

const controlAccentColor = function () {
  optionsView.accentColor = optionsView.accentColor;

  model.saveColorToLocalStorage(optionsView.accentColor);
};

const controlDarkMode = function (e) {
  // this function takes the event received from darkModeView.addHandlerDarkMode() as first argument and model.state.darkMode as second.
  // toggleDarkMode returns false if darkmMde off and true if darkMode on
  model.state.darkMode = darkModeView.toggleDarkModeSwitch(
    e,
    model.state.darkMode
  );

  darkModeView.darkMode(model.state.darkMode);
  model.saveDarkModeToLocalStorage(model.state.darkMode);
};
//
//
//

//

//
//
//
//
//
//
//
const appInit = function () {
  // Loads accent color from localStorage
  const color = model.loadColorFromLocalStorage();

  // sets the color in the DOM (setter property in optionsView class)
  optionsView.accentColor = color;

  // saves to state
  model.state.accentColor = optionsView.accentColor;

  if (localStorage.getItem("accent") === null) optionsView.setDefaultColor(); // initializes the colorInput with a default color
  if (localStorage.getItem("darkMode") === null)
    localStorage.setItem("darkMode", "false");

  // Loads darkMode

  model.state.darkMode = JSON.parse(
    localStorage.getItem("darkMode").toLowerCase()
  );

  darkModeView.darkMode(model.state.darkMode);
  if (model.state.darkMode) darkModeView.turnOnSwitch();

  // ABOUT

  const isVisited = localStorage.getItem("visited");
  if (!isVisited) {
    localStorage.setItem("visited", true);
    optionsView.toggleAbout();
  }

  // Loads tasks from storage

  const localStorageTasks = model
    .getTasksFromLocalStorage()
    .sort((a, b) => a.id - b.id);

  localStorageTasks.forEach((taskInLocalStorage) => {
    model.state.tasks.push(taskInLocalStorage);
  });

  model.state.tasks.forEach((taskInState) => {
    // if (i === 0) return;
    addTaskView.renderTask(taskInState);
  });

  if (model.state.tasks.length < 1) addTaskView.enableAddTaskMessage();

  // adds event listeners
  taskView.addMenuHandler(controlMenu);
  3;

  addTaskView.addHandlerNewTask(controlAddTasks);

  // taskView.addHandlerCopy(controlCopy);
  optionsView
    .addHandlerDeleteAll(controlDeleteAll, model.state.tasks)
    .addHandlerAccentColor(controlAccentColor);

  // This function handles mark complete, copy, edit and delete (This is why I passed so many callbacks into it)
  taskManagerView.handleInterface(
    model.state,
    controlSavedTaskUpdate,
    controlEdit,
    controlDelete,
    controlUndo,
    model.timeoutReference
  );

  darkModeView.addHandlerDarkMode(controlDarkMode);

  optionsView.addHandlerAbout();
};
appInit();

import Views from "./Views.js";

class OptionView extends Views {
  confirmModal = document.querySelector(".modal--window-delete-all");
  // Accent color
  inputColor = document.querySelector(".accent-color");

  addHandlerDeleteAll(handler, tasks) {
    //add handler for opening options menu
    document
      .querySelector(".app--menu-btn")
      .addEventListener("click", this.toggleOptionsView.bind(this));

    this.lblDeleteAll.addEventListener("click", () => {
      this.options.classList.add("hidden");
      this.showOverlay(this.confirmModal);
    });

    this.btnYes.addEventListener("click", () => {
      //

      if (tasks.length < 1) {
        this.displayMessage("There are no tasks to delete");
        this.hideOverlay(this.confirmModal);
        return;
      }

      handler();
      // removes clears task--item-container and adds back the title element.
      document.querySelector(".title");
      this.tasksContainer.innerHTML = `      
        <div class="title">
          <p>To do</p>
        </div>`;

      this.enableAddTaskMessage();

      this.hideOverlay(this.confirmModal);
    });

    this.btnNo.addEventListener("click", () => {
      this.hideOverlay(this.confirmModal);
    });

    return this;
  }

  addHandlerAccentColor(handler) {
    this.inputColor.addEventListener("change", handler);
  }

  addHandlerAbout() {
    document
      .querySelector(".label-about")
      .addEventListener("click", this.toggleAbout.bind(this));

    document.querySelector(".close--about").addEventListener("click", () => {
      this.toggleAbout();
    });
  }

  toggleAbout() {
    if (!this.options.classList.contains("hidden"))
      this.hideOverlay(this.options);
    this.about.classList.toggle("hidden");
  }
  get accentColor() {
    return this.inputColor.value;
  }

  set accentColor(color) {
    // updates CSS variable with new color
    document.querySelector(":root").style.setProperty("--primary-color", color);

    // sets inputColor to received color
    this.inputColor.value = color;
  }

  toggleOptionsView(e) {
    if (!e.target.closest(".app--menu-btn")) return;

    // This method shows overlay under a element that's passed into it and triggers hiddden class
    this.showOverlay(this.options);
  }

  setDefaultColor() {
    this.inputColor.value = "#0077b6";
  }
}

export default new OptionView();

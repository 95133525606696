import { UNDO_SECONDS } from "../../config.js";
import Views from "./Views.js";

class TaskManagerInterface extends Views {
  handleInterface(
    state,
    taskUpdateHandler,
    controlEdit,
    controlDelete,
    controlUndo,
    timeoutReference
  ) {
    document.addEventListener("click", (e) => {
      if (e.target.closest(".btn-complete")) {
        this.completeTask(state, taskUpdateHandler);
      }
      if (e.target.closest(".btn-copy")) {
        this.copyText(state.activeTaskId);
        this.displayMessage("Copied!");
      }
      if (e.target.closest(".btn-edit")) {
        this.showOverlay(this.modal);

        this.inputField.value = state.tasks[state.activeTaskId - 1].text;

        this.inputField.select();

        this.showSaveBtn();
      }
      if (e.target.closest(".btn--save")) {
        controlEdit();
        this.clearMainTextField();
        this.showOverlay(this.modal);
        this.hideOverlay(this.modal);
      }
      if (e.target.closest(".btn-delete")) {
        // returns the task to be hidden in DOM
        controlDelete().classList.add("hidden");
        this.bannerUndo.classList.remove("hidden");

        // removes task--menu
        document.querySelector(".task--menu")?.remove();

        this.btnUndo.addEventListener("click", () => {
          controlUndo().forEach((deletedTask) =>
            deletedTask.taskElement.classList.remove("hidden")
          );
          this.displayMessage("Undone!");
          this.bannerUndo.classList.add("hidden");

          if (this.tasksContainer.classList.contains("hidden"))
            this.disableAddTaskMessage();
        });

        // Clears previusly executed timeout, preventing banner undo flicker.

        clearTimeout(timeoutReference);
        timeoutReference = setTimeout(() => {
          this.btnUndo.removeEventListener("click", controlUndo);
          this.bannerUndo.classList.add("hidden");
        }, UNDO_SECONDS * 1000);
        console.log(timeoutReference);
      }
    });
  }

  completeTask(state, taskUpdateHandler) {
    const activeTaskId = state.activeTaskId;

    const completedTask = this.findTaskWithId(activeTaskId);

    // Adds / removes completed class on task--item
    completedTask.classList.toggle("completed");

    // Getting the svg icon and toggling hidden class
    completedTask.children[0].classList.toggle("hidden");

    //

    // Setting task in state as completed dynamically
    state.tasks.forEach((task) => {
      if (task.id === +activeTaskId) {
        task.completed = !task.completed;
        taskUpdateHandler(task);
        return;
      }
    });
  }

  copyText(id) {
    const taskToCopy = this.findTaskWithId(id);
    const taskToCopyChildren = [...taskToCopy.children];
    //prettier-ignore
    const textToCopy = `${taskToCopyChildren[1].textContent.trim()}${taskToCopyChildren[2] ? "\n" + "\n" + (taskToCopyChildren[2].textContent.trim()):""}`;
    navigator.clipboard.writeText(textToCopy);
  }

  // edit(id) {
  //   // 1) get element
  //   console.log(id);

  //   this.toggleEditModal();
  //   handler(console.log("handler execution"));
  //   this.toggleEditModal();
  //   // task.textContent = "test";

  //   // console.log();
  //   // const taskText =
  //   // 2) open modal

  //   // 3) Add condition to done btn to
  // }

  updateTask(taskToEdit, text, due = false) {
    taskToEdit.children[1].textContent = text;
    // continue here
    if (!due) return;
    const when = this.getDay(due);
    // if (taskToEdit.children[2]) taskToEdit.children[2].remove();

    const dueByNewMarkup = `
    <div class="task--due">
      <p>Due by&nbsp;</p><span class="task--duetime">${when[0]}</span><span class="task--duedate">&nbsp;${when[1]}</span>
    </div>`;
    taskToEdit.insertAdjacentHTML("beforeend", dueByNewMarkup);
  }

  // toggleEditModal() {
  //   const overlay = document.querySelector(".overlay");
  //   const modal = document.querySelector(".modal--window");
  //   this.showSaveBtn();
  //   overlay.classList.toggle("hidden");
  //   modal.classList.toggle("hidden");
  // }

  showSaveBtn() {
    this.btnSave.classList.remove("hidden");
    this.btnDone.classList.add("hidden");
  }

  updateTaskMenu(due) {
    const dueByArray = this.getDay(due);
    const output = `
    <svg xmlns="http://www.w3.org/2000/svg" class="edited--icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
</svg> <span>Due by ${dueByArray[0]} ${dueByArray[1]}</span>`;

    const taskMenuDueby = document.querySelector(".task--menu-dueby");

    const menuInfo = document.querySelector(".task--information");

    if (taskMenuDueby) taskMenuDueby.innerHTML = output;
    else
      menuInfo.insertAdjacentHTML(
        "beforeend",
        `<p class="task--menu-dueby">${output}</p>`
      );
  }

  getTextFromTask(id) {
    console.log(id);

    return this.findTaskWithId(id).textContent;
  }
}

export default new TaskManagerInterface();

import {
  DARK_MODE_PRIMARY_COLOR,
  DARK_MODE_SECONDARY_COLOR,
  DARK_MODE_TEXT_COLOR,
  LIGHT_MODE_PRIMARY_COLOR,
  LIGHT_MODE_SECONDARY_COLOR,
  LIGHT_MODE_TEXT_COLOR,
} from "../../config";
import Views from "./Views";

class DarkMode extends Views {
  darkModeElement = document.querySelector(".dark-mode--parent");
  darkModeSwitchContainer = document.querySelector(
    ".dark-mode--switch-container"
  );

  addHandlerDarkMode(handler) {
    this.darkModeElement.addEventListener("click", (e) => {
      handler(e);
    });
  }

  toggleDarkModeSwitch(e, darkMode) {
    if (!e.target.closest(".dark-mode--parent")) return;
    return this.isSwitched(darkMode);
  }

  isSwitched(darkMode) {
    if (darkMode) {
      this.darkModeSwitchContainer.classList.remove("switch-container--active");
      return false;
    } else {
      this.darkModeSwitchContainer.classList.add("switch-container--active");
      return true;
    }
  }

  turnOnSwitch() {
    this.darkModeSwitchContainer.classList.add("switch-container--active");
  }

  darkMode(active = false) {
    const doc = document.querySelector("html");
    const root = document.querySelector(":root");
    const modals = document.querySelectorAll(".modal");
    const header = document.querySelector(".header--container");

    // this.options

    // --task-item-color
    // document.querySelector(":root").style.setProperty("--primary-color", color);

    if (active) {
      header.style.backgroundColor = DARK_MODE_SECONDARY_COLOR;
      doc.style.backgroundColor = DARK_MODE_PRIMARY_COLOR;
      document
        .querySelector(":root")
        .style.setProperty("--task-item-color", DARK_MODE_SECONDARY_COLOR);
      root.style.setProperty("--text-color", DARK_MODE_TEXT_COLOR);
      this.options.style.backgroundColor = DARK_MODE_SECONDARY_COLOR;
      modals.forEach(
        (modal) => (modal.style.backgroundColor = DARK_MODE_SECONDARY_COLOR)
      );
      this.inputField.style.backgroundColor = DARK_MODE_SECONDARY_COLOR;
      this.about.style.backgroundColor = DARK_MODE_PRIMARY_COLOR;
    } else {
      header.style.backgroundColor = LIGHT_MODE_SECONDARY_COLOR;
      doc.style.backgroundColor = LIGHT_MODE_PRIMARY_COLOR;
      document
        .querySelector(":root")
        .style.setProperty("--task-item-color", LIGHT_MODE_SECONDARY_COLOR);
      root.style.setProperty("--text-color", LIGHT_MODE_TEXT_COLOR);
      this.options.style.backgroundColor = LIGHT_MODE_SECONDARY_COLOR;

      modals.forEach(
        (modal) => (modal.style.backgroundColor = LIGHT_MODE_SECONDARY_COLOR)
      );
      this.inputField.style.backgroundColor = LIGHT_MODE_SECONDARY_COLOR;
      this.about.style.backgroundColor = LIGHT_MODE_PRIMARY_COLOR;
    }
  }

  setDefaultColor() {}
}

export default new DarkMode();

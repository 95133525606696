import {
  LOCALE,
  TIME_STYLE,
  DATE_STYLE,
  MESSAGE_DISPLAY_SECONDS,
} from "../../config.js";

export default class Views {
  btnAdd = document.querySelector(".btn--new-task");
  btnCancel = document.querySelector(".btn--cancel");
  btnDone = document.querySelector(".btn--done");
  btnSave = document.querySelector(".btn--save");
  btnUndo = document.querySelector(".btn--undo");
  inputField = document.querySelector(".task--input-field");
  chkDueBy = document.querySelector(".checkbox--dueby");
  dateControl = document.querySelector(".dueby--input-field");
  taskMenu = document.querySelector(".task--menu");
  tasksContainer = document.querySelector(".tasks--container");
  overlay = document.querySelector(".overlay");
  modal = document.querySelector(".modal--window");
  bannerUndo = document.querySelector(".banner--undo");

  // Selectors for options
  options = document.querySelector(".app--menu--container");
  lblDeleteAll = document.querySelector(".delete-all-tasks");
  confirmModal = document.querySelector(".modal--window-delete-all");
  btnYes = document.querySelector(".btn--yes");
  btnNo = document.querySelector(".btn--no");
  about = document.querySelector(".about--container");

  getDay(date) {
    const today = new Date();
    const dateFromInput = new Date(date);
    const dueByTime = new Intl.DateTimeFormat(LOCALE, TIME_STYLE).format(
      dateFromInput
    );

    if (
      dateFromInput.getDate() === today.getDate() &&
      dateFromInput.getMonth() === today.getMonth() &&
      dateFromInput.getYear() === today.getYear()
    )
      return [dueByTime, "today"];

    if (
      dateFromInput.getDate() + 1 === today.getDate() &&
      dateFromInput.getMonth() === today.getMonth() &&
      dateFromInput.getYear() === today.getYear()
    )
      return [dueByTime, "yesterday"];

    if (
      dateFromInput.getDate() - 1 === today.getDate() &&
      dateFromInput.getMonth() === today.getMonth() &&
      dateFromInput.getYear() === today.getYear()
    )
      return [dueByTime, "tomorrow"];
    else
      return [
        new Intl.DateTimeFormat(LOCALE, TIME_STYLE).format(dateFromInput),
        `on ${new Intl.DateTimeFormat(LOCALE, DATE_STYLE).format(
          dateFromInput
        )}`,
      ];
  }

  displayMessage(message) {
    //     banner--message
    // banner--message-text
    const bannerElement = document.querySelector(".banner--message");
    bannerElement.textContent = message;

    bannerElement.classList.remove("hidden");
    setTimeout(
      () => bannerElement.classList.add("hidden"),
      MESSAGE_DISPLAY_SECONDS * 1000
    );
  }

  enableAddTaskMessage() {
    document.querySelector(".tasks--container").classList.toggle("hidden");
    document.querySelector(".add--message").classList.toggle("hidden");
  }
  disableAddTaskMessage() {
    document.querySelector(".tasks--container").classList.remove("hidden");
    document.querySelector(".add--message").classList.add("hidden");
  }

  getTaskId(e) {
    const id = e.target.closest(".task--item")?.dataset.id;

    if (!id) return false;
    else return id;
  }

  findTaskWithId(id) {
    // 1) creates array from HTMLCollection returned by .children
    // (Needed to be able to use findIndex() which is only available in arrays)
    const allChildren = [...this.tasksContainer.children];
    const matchIndex = allChildren.findIndex(
      (child) => child.dataset.id === id
    );
    return allChildren[matchIndex];
  }

  renderTask(task) {
    const when = this.getDay(task.due);

    const parentElement = document.querySelector(".tasks--container");

    const basicMarkup = `
    <div class="task--item ${task.completed ? "completed" : ""}" data-id="${
      task.id
    }">
      <svg xmlns="http://www.w3.org/2000/svg" class="completed--icon ${
        task.completed ? "" : "hidden"
      }" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
    </svg>
      <p class= "task--item-text">${task.text}</p>
    </div>`;

    const advancedMarkup = `
    
    <div class="task--item ${task.completed ? "completed" : ""}" data-id="${
      task.id
    }">
      <svg xmlns="http://www.w3.org/2000/svg" class="completed--icon ${
        task.completed ? "" : "hidden"
      }" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
     <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
      </svg>
      <p class= "task--item-text">${task.text}</p>

    <div class="task--due">
      <p>Due by&nbsp;</p><span class="task--duetime">${
        when[0]
      }</span><span class="task--duedate">&nbsp;${when[1]}</span>
    </div>
  </div>`;

    parentElement.insertAdjacentHTML(
      "beforeend",
      task.due ? advancedMarkup : basicMarkup
    );
  }

  resetModal() {
    if (this.chkDueBy.checked) this.chkDueBy.checked = false;
    this.toggleDueBy(this.chkDueBy.checked);
    this.inputField.value = "";
  }
  toggleDueBy() {
    if (this.chkDueBy.checked) {
      this.dateControl.value = "";
      this.dateControl.classList.remove("hidden");
    } else this.dateControl.classList.add("hidden");
  }

  clearMainTextField() {
    const mainTextField = document.querySelector(".task--input-field");

    mainTextField.value = "";
  }

  getUserInputs() {
    // This function egets the input from the modal. It is called under two contexts: when clicked on addTask button and when editting tasks (click on save btn in modal)
    // called in modules addTaskView.js and taskManagerView.js

    // gets the date converted to ISO string, if not date is provided, false is returned

    // if (!this.dateControl) {
    //   this.displayMessage("Invalid date");
    //   return;
    // }

    try {
      const due = this.chkDueBy.checked
        ? new Date(this.dateControl.value).toISOString()
        : false;
      // returns an array with the possible user inputs
      return [this.inputField.value, due];
    } catch {
      return [];
    }
  }

  showOverlay(elementOnTop) {
    elementOnTop.classList.remove("hidden");
    this.overlay.classList.remove("hidden");

    this.overlay.addEventListener(
      "click",
      this.hideOverlay.bind(this, elementOnTop)
    );
  }

  hideOverlay(elementOnTop) {
    elementOnTop.classList.add("hidden");
    this.overlay.classList.add("hidden");
  }
}

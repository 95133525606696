import { DATE_STYLE, TIME_STYLE, LOCALE } from "../../config.js";
import { state } from "../model.js";
import Views from "./Views.js";

class TaskView extends Views {
  addMenuHandler(handler) {
    this.tasksContainer.addEventListener("click", (e) => {
      //

      const taskMenu = document.querySelector(".task--menu");
      //
      // Checks if (task.menu is truthy and that the closest element to the click event is NOT the task menu itself (this preserves the menu if click happens within it))
      if (taskMenu && !e.target.closest(".task--menu")) {
        //
        // checks if the click happened on a task--item that's not the same as the one active. If true, the menu is removed and opened on the clicked task--item (handled by handler() )
        if (
          e.target.closest(".task--item")?.dataset.id !== taskMenu.dataset.id
        ) {
          this._removeMenu();
          handler(e);
        } else this._removeMenu(); // else, just removes the menu (meaning the click happened on the same task--item that is active)
      } else handler(e); // if there isn't a task--menu rendered, renders one on the task--item clicked (example, when the application first opens)
    });
  }
  //
  //
  //
  //
  //
  // addCompletionHandler(handler) {
  //   document.addEventListener("click", function (e) {
  //     if (!e.target.closest(".btn-complete")) return;
  //     console.log(e.target);
  //   });
  // }

  //
  //
  //
  //
  //
  // addHandlerCopy(handler) {
  //   document.addEventListener("click", function (e) {
  //     if (!e.target.closest(".btn-copy")) return;
  //     handler();
  //   });
  // }

  //
  //
  //
  //
  //

  //
  //
  //
  //
  //
  //
  //
  //
  //
  // findTaskWithId(id) {
  //   // 1) creates array from HTMLCollection returned by .children
  //   // (Needed to be able to use findIndex() which is only available in arrays)
  //   const allChildren = [...this._tasks.children];
  //   const matchIndex = allChildren.findIndex(
  //     (child) => child.dataset.id === id
  //   );
  //   return allChildren[matchIndex];
  // }
  //
  //
  //
  //
  //
  // Finds object that matches id in tasksArray
  getTaskObjectWithId(id, tasksArray) {
    return tasksArray.filter((task) => task.id === +id);
  }
  //
  //
  //
  //
  //
  _removeMenu() {
    const taskMenu = document.querySelector(".task--menu");
    if (!taskMenu) return;
    taskMenu.remove();
  }
  //
  //
  //
  //
  //

  //
  //
  //
  //
  //

  renderMenu(id, taskObject) {
    const getCreationDateString = function (date) {
      const dateObject = new Date(date);
      return new Intl.DateTimeFormat(LOCALE, {
        ...DATE_STYLE,
        ...TIME_STYLE,
      }).format(dateObject);
    };

    // 1) Get element to insert html into
    const insertMenuInto = this.findTaskWithId(id);

    const createdAt = getCreationDateString(taskObject[0].created);

    const markup = `<div class="task--menu" data-id="${id}">
    <div class="task--information">

      <p class="task--information-content">
        Task created on ${createdAt}
      </p>

${
  taskObject[0].due
    ? `<p class = "task--menu-dueby">${
        taskObject[0].edited
          ? `<svg xmlns="http://www.w3.org/2000/svg" class="edited--icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
      </svg>`
          : ""
      }Due by ${this.getDay(taskObject[0].due).join(" ")} </p>`
    : ""
}

    </div>

    <div class="task--interface-container">

      <button class="iconbtn btn-complete">
        <svg xmlns="http://www.w3.org/2000/svg" class="task--interface-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
        </svg>
    </button>

    <button class="iconbtn btn-copy">
      <svg xmlns="http://www.w3.org/2000/svg" class="task--interface-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
      </svg>
    </button>

      <button class="iconbtn btn-edit">
        <svg xmlns="http://www.w3.org/2000/svg" class="task--interface-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>

      </button>
      <button class="iconbtn btn-delete">
        <svg xmlns="http://www.w3.org/2000/svg" class="task--interface-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>

      </button>

    </div>
  </div>`;

    ///
    ///
    ///

    // 2) remove old menu if any
    this._removeMenu();

    // 3) Insert html into element

    insertMenuInto.insertAdjacentHTML("afterend", markup);

    // 4) Scroll into view
    document
      .querySelector(".task--menu")
      .scrollIntoView({ block: "center", behavior: "smooth" });
  }
}

export default new TaskView();
